import { useState } from 'react';
import firebase from 'firebase';

type Result = {
  loading: boolean
  error: Error | undefined
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

export const useAuth = (): Result => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const login = async (email: string, password: string) => {
    if (loading) return;
    setLoading(true);
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = async () => {
    if (loading) return;
    setLoading(true);
    await firebase
      .auth()
      .signOut()
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    login,
    logout,
  };
};
