import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { PosFile } from '@/entities';

export const usePosFiles = (
  accountId: string | undefined,
  storeId: string | undefined,
  posType: string | undefined,
  limit = 1
): [PosFile[] | undefined, boolean, Error | undefined] =>
  useCollectionData<PosFile>(accountId && storeId && posType &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('stores')
      .doc(storeId)
      .collection('pos')
      .doc(posType)
      .collection('posFiles')
      .limit(limit)
      .orderBy('createTime', 'desc')
  );
