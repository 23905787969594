import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Platform } from '@/entities';

export const useStorePlatforms = (
  accountId: string | undefined,
  storeId: string | undefined
): [Platform[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Platform>(
    accountId && storeId &&
    firebase
      .firestore()
      .collectionGroup('platforms')
      .where('accountId', '==', accountId)
      .where('storeId', '==', storeId),
    { idField: 'id' },
  );
