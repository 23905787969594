import firebase from 'firebase';
import { OrderDispatch } from '@/entities';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export const useOrderDispatches = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined,
  orderId: string | undefined
): [OrderDispatch[] | undefined, boolean, Error | undefined] =>
  useCollectionData<OrderDispatch>(
    accountId && storeId && brandId && orderId &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('stores')
      .doc(storeId)
      .collection('brands')
      .doc(brandId)
      .collection('orders')
      .doc(orderId)
      .collection('orderDispatches')
      .where('orderId', '==', orderId),
    { idField: 'id' },
  )