import firebase from 'firebase';
import { usePaginationData } from 'react-firebase-pagination-hooks';
import { Order } from '@/entities';
import { PaginationHook } from 'react-firebase-pagination-hooks/dist/util/usePaginationValue';

export const useOrders = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined
): PaginationHook<Order> => {
  const createQuery = () => {
    if (accountId === undefined || storeId === undefined) {
      return undefined;
    }

    let query = firebase
      .firestore()
      .collectionGroup('orders')
      .where('accountId', '==', accountId)
      .where('storeId', '==', storeId)
      .limit(10)
      .orderBy('createTime', 'desc');

    if (brandId != undefined) {
      query = query.where('brandId', '==', brandId);
    }

    if (startDate != undefined) {
      query = query.where('createTime', '>=', startDate);
    }

    if (endDate != undefined) {
      query = query.where('createTime', '<=', endDate);
    }

    return query;
  };

  return usePaginationData<Order>(createQuery(), { idField: 'id' });
};
