import { CallableResult, useCallable } from '@/utils';
import { PlatformType } from '@/entities';

type Request = {
  storeId: string
  brandId: string
  platformType: PlatformType
}

export const useImportItems = (): CallableResult<Request, void> =>
  useCallable('onCallImportItems');
