import { CallableResult, useCallable } from '@/utils';
import { DeliveryMethod, PlatformType } from '@/entities';

interface ChangeDeliveryMethodOperation {
  type: 'CHANGE_DELIVERY_METHOD';
  deliveryMethod: DeliveryMethod;
}

interface ChangePickupPosItemEnabledOperation {
  type: 'CHANGE_PICKUP_POS_ITEM_ENABLED';
  enabled: boolean;
}

type Request = {
  operation: ChangeDeliveryMethodOperation | ChangePickupPosItemEnabledOperation;
  storeId: string;
  brandId: string;
  platformType: PlatformType;
}

export const usePlatformOperation = (): CallableResult<Request, void> =>
  useCallable('onCallPlatformOperation');
