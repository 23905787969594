import { CallableResult, useCallable } from '@/utils';
import { OrderLinkagePlatform, PosItem, PosPlatform, Store } from '@/entities';

type LinktoParams = { type: 'linkto', username: string, password: string };
type OrderLinkagePosParams = { type: 'ngp', businessCode: string, storeCode: string };
type StessoPosParams = { type: 'stesso', username: string, password: string };

interface CheckAccountOperation {
  operation: 'CHECK_ACCOUNT'
  pos: Pick<PosPlatform, 'type'>
  username: string
  password: string
}

interface CheckMenuOperation {
  operation: 'CHECK_MENU'
  store: Pick<Store, 'id'>
  pos: Pick<PosPlatform, 'type'>
  filePath: string
}

interface ImportMenuOperation {
  operation: 'IMPORT_MENU'
  storeId: string
  posType: 'ngp'
  draftPos: Pick<OrderLinkagePlatform, 'businessCode' | 'storeCode'> | null
}

interface SaveMenuOperation {
  operation: 'SAVE_MENU'
  store: Pick<Store, 'id'>
  pos: LinktoParams | StessoPosParams | OrderLinkagePosParams
  items: PosItem[]
}

interface UpdateMenuOperation {
  operation: 'UPDATE_MENU'
  store: Pick<Store, 'id'>
  pos: Pick<PosPlatform, 'type'>
  items: PosItem[]
}

type Request = CheckAccountOperation | CheckMenuOperation | ImportMenuOperation | SaveMenuOperation | UpdateMenuOperation

interface Response {
  items?: PosItem[]
}

export const usePosOperation = (): CallableResult<Request, Response> =>
  useCallable('onCallPosOperation');
