import { IdentityRequest, PlatformRelation, PlatformType } from '@/entities';
import { CallableResult, useCallable } from '@/utils';

type ParamsCommon = PlatformRelation<PlatformType> & {
  type: 'registration' | 'activation' | 'deactivation' | 'deletion'
  executeTime: string
};

type Request = {
  operation: ({ type: 'registration' } & { identities: IdentityRequest[] } & ParamsCommon)
    | ({ type: 'activation' } & { identities: IdentityRequest[] } & ParamsCommon)
    | ({ type: 'deactivation' } & ParamsCommon)
    | ({ type: 'deletion' } & ParamsCommon)
    | ({ type: 'cancel' } & PlatformRelation<PlatformType>)
}

export const useRegisterPlatform = (): CallableResult<Request, unknown> =>
  useCallable('onCallRegisterPlatform');
