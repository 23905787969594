import {
  BotEatsIdentity,
  Brand,
  DemaecanIdentity,
  DemaecanOfficialIdentity,
  HasRawPassword,
  IdentityRequest,
  Item,
  MenuIdentity,
  PlatformOperationType,
  PlatformType,
  Store,
  SukesanIdentity,
  UberEatsIdentity,
  UberEatsOfficialIdentity,
  WoltIdentity,
  WoltOfficialIdentity
} from '@/entities';
import { useAppContext, useRegisterBrand, useRegisterPlatform, useRegisterStore } from '@/hooks';
import { PlatformFormData } from '@/partials';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useGetMenu } from '@/hooks/calls/useGetMenu';

export enum RegistrationStep {
  STORE = '店舗登録',
  BRAND = 'ブランド登録',
  PLATFORM = 'プラットフォーム連携',
  MENU = 'メニュー確認',
}

type OperationTypes = 'STORE' | 'BRAND' | 'PLATFORM';
type StoreParams = Pick<Store, 'name' | 'email'>;
type BrandParams = { id: string | undefined, name: string };

type Result = {
  allSteps: RegistrationStep[]
  step: RegistrationStep
  store: StoreParams | undefined
  brand: BrandParams | undefined
  menu: Omit<Item, 'accountId' | 'storeId' | 'brandId'>[] | undefined
  submitPlatform: (platformParams: PlatformFormData) => Promise<void>
  submitStore: (store: StoreParams) => void
  submitBrand: (brand: BrandParams) => void
  submitMenu: () => void
  goBack: () => void
  loading: boolean
  error: Error | undefined
};

export const useRegistrationState = (
  operationType: OperationTypes,
  platformOperationType: PlatformOperationType,
  initialStore?: Store,
  initialBrand?: Brand
): Result => {
  const { account } = useAppContext();

  const router = useRouter();
  const [step, setStep] = useState<RegistrationStep>(initialStep(operationType));
  const [store, setStore] = useState<StoreParams>();
  const [brand, setBrand] = useState<BrandParams>();
  const [identities, setIdentities] = useState<IdentityRequest[]>();
  const [menu, setMenu] = useState<Omit<Item, 'accountId' | 'storeId' | 'brandId'>[]>();
  const [executeTime, setExecuteTime] = useState<Date>();

  const getMenu = useGetMenu();
  const registerStore = useRegisterStore();
  const registerBrand = useRegisterBrand();
  const registerPlatform = useRegisterPlatform();

  const goBack = () => {
    setStep(step => {
      switch (step) {
        case RegistrationStep.STORE: throw new Error('There is no step before STORE');
        case RegistrationStep.BRAND: return RegistrationStep.STORE;
        case RegistrationStep.PLATFORM: return RegistrationStep.BRAND;
        case RegistrationStep.MENU: return RegistrationStep.PLATFORM;
      }
    })
  };

  const submitStore = (store: StoreParams) => {
    setStore(store);
    setStep(RegistrationStep.BRAND);
  };

  const submitBrand = (brand: BrandParams) => {
    setBrand(brand);
    setStep(RegistrationStep.PLATFORM);
  };

  const submitPlatform = async (formData: PlatformFormData) => {
    if (platformOperationType === 'registration' || platformOperationType === 'activation') {
      const identities = mapFormDataToIdentities(formData);
      setIdentities(identities);

      const { menu } = await getMenu.call({ platformType: formData.type, identities });

      setMenu(menu);
      setExecuteTime(new Date(formData.executeTime));
      setStep(RegistrationStep.MENU);
    } else if (initialStore && initialBrand) {
      await registerPlatform.call({
        operation: {
          type: platformOperationType,
          accountId: initialStore.accountId,
          storeId: initialStore.id,
          brandId: initialBrand.id,
          platformType: formData.type,
          executeTime: new Date(formData.executeTime).toISOString()
        }
      });
      await router.push(`/stores/${initialStore.id}/brands/${initialBrand.id}?platform=${formData.type}`);
    } else {
      throw new Error('store or brand is missing');
    }
  };

  const submitMenu = async () => {
    switch (operationType) {
      case 'STORE': {
        if (!account || !store || !identities || !executeTime) {
          return;
        }

        const { store: createdStore } = await registerStore.call({ store });
        const { brand: createdBrand } = brand?.id
          ? { brand: { ...brand, id: brand.id } }
          : await registerBrand.call({ brand, storeId: createdStore.id });

        await registerPlatform.call({
          operation: {
            type: 'registration',
            accountId: account.id,
            storeId: createdStore.id,
            brandId: createdBrand.id,
            platformType: identities[0].platformType,
            executeTime: executeTime.toISOString(),
            identities
          }
        });
        await router.push(`/stores/${createdStore.id}/brands/${createdBrand.id}?platform=${identities[0].platformType}`);
        break;
      }
      case 'BRAND': {
        if (!account || !initialStore || !identities || !executeTime) return;

        const { brand: createdBrand } = brand?.id
          ? { brand: { ...brand, id: brand.id } }
          : await registerBrand.call({ brand: brand, storeId: initialStore.id });

        await registerPlatform.call({
          operation: {
            type: 'registration',
            accountId: account.id,
            storeId: initialStore.id,
            brandId: createdBrand.id,
            platformType: identities[0].platformType,
            executeTime: executeTime.toISOString(),
            identities
          }
        });
        await router.push(`/stores/${initialStore.id}/brands/${createdBrand.id}?platform=${identities[0].platformType}`);
        break;
      }
      case 'PLATFORM': {
        if (!account || !initialStore || !initialBrand || !identities || !executeTime) return;

        await registerPlatform.call({
          operation: {
            type: platformOperationType,
            accountId: account.id,
            storeId: initialStore.id,
            brandId: initialBrand.id,
            platformType: identities[0].platformType,
            executeTime: executeTime.toISOString(),
            identities
          }
        });
        await router.push(`/stores/${initialStore.id}/brands/${initialBrand.id}?platform=${identities[0].platformType}`);
        break;
      }
    }
  };

  return {
    allSteps: allSteps(operationType),
    step,
    store,
    brand,
    menu,
    submitPlatform,
    submitStore,
    submitBrand,
    submitMenu,
    goBack,
    loading: getMenu.loading || registerStore.loading || registerBrand.loading || registerPlatform.loading,
    error: getMenu.error || registerStore.error || registerBrand.error || registerPlatform.error
  };
};

const allSteps = (type: OperationTypes): RegistrationStep[] => {
  switch (type) {
    case 'STORE': return [RegistrationStep.STORE, RegistrationStep.BRAND, RegistrationStep.PLATFORM, RegistrationStep.MENU];
    case 'BRAND': return [RegistrationStep.BRAND, RegistrationStep.PLATFORM, RegistrationStep.MENU];
    case 'PLATFORM': return [RegistrationStep.PLATFORM, RegistrationStep.MENU];
  }
}

const initialStep = (type: OperationTypes): RegistrationStep => {
  switch (type) {
    case 'STORE': return RegistrationStep.STORE;
    case 'BRAND': return RegistrationStep.BRAND;
    case 'PLATFORM': return RegistrationStep.PLATFORM;
  }
}

const mapFormDataToIdentities = (formData: PlatformFormData): IdentityRequest[] => {
  switch(formData.type) {
    case PlatformType.BOTEATS:
      return [{
        platformType: PlatformType.BOTEATS as const,
        applicationType: 'api' as const,
        loginId: formData.email,
        password: formData.password
      } as BotEatsIdentity<HasRawPassword>];
    case PlatformType.DEMAECAN:
      return [
        {
          platformType: PlatformType.DEMAECAN,
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as DemaecanIdentity<HasRawPassword>,
        {
          platformType: PlatformType.DEMAECAN,
          applicationType: 'adminWeb',
          loginId: formData.adminId,
          password: formData.adminPassword,
          code: formData.adminCode
        } as DemaecanIdentity<HasRawPassword>
      ];
    case PlatformType.DEMAECAN_OFFICIAL:
      return [{
        platformType: PlatformType.DEMAECAN_OFFICIAL,
        applicationType: 'api',
        loginId: formData.uuid,
        corpId: formData.corpId,
        token: formData.token
      } as DemaecanOfficialIdentity];
    case PlatformType.MENU_OFFICIAL:
      return [{
        platformType: PlatformType.MENU_OFFICIAL,
        applicationType: 'api',
        loginId: formData.uuid
      }];
    case PlatformType.MENU:
      return [{
        platformType: PlatformType.MENU,
        applicationType: 'appApi',
        loginId: formData.email,
        password: formData.password
      } as MenuIdentity<HasRawPassword>];
    case PlatformType.SUKESAN:
      return [{
        platformType: PlatformType.SUKESAN,
        store_code: formData.store_code,
      } as SukesanIdentity];
    case PlatformType.UBEREATS:
      return [{
        platformType: PlatformType.UBEREATS,
        applicationType: 'appApi',
        loginId: formData.email,
        password: formData.password
      } as UberEatsIdentity<HasRawPassword>];
    case PlatformType.UBEREATS_OFFICIAL:
      return [{
        platformType: PlatformType.UBEREATS_OFFICIAL,
        loginId: formData.store_id,
      } as UberEatsOfficialIdentity];
    case PlatformType.WOLT:
      return [
        {
          platformType: PlatformType.WOLT,
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as WoltIdentity<HasRawPassword>,
        {
          platformType: PlatformType.WOLT,
          applicationType: 'adminApi',
          loginId: formData.email,
          password: formData.password
        } as WoltIdentity<HasRawPassword>
      ];
    case PlatformType.WOLT_OFFICIAL:
      return [
        {
          platformType: PlatformType.WOLT_OFFICIAL,
          applicationType: 'api',
          type: 'order',
          loginId: formData.venueId,
          token: formData.token
        } as WoltOfficialIdentity<HasRawPassword>,
        {
          platformType: PlatformType.WOLT_OFFICIAL,
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as WoltOfficialIdentity<HasRawPassword>,
        {
          platformType: PlatformType.WOLT_OFFICIAL,
          applicationType: 'adminApi',
          loginId: formData.email,
          password: formData.password
        } as WoltOfficialIdentity<HasRawPassword>
      ];
  }
}
