import firebase from 'firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Account } from '@/entities';

export const useAccount = (accountId: string | undefined): [Account | undefined, boolean, Error | undefined] =>
  useDocumentData<Account>(
    accountId &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId),
    { idField: 'id' },
  );
