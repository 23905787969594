import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CallableResult, useCallable } from '@/utils';
import { Analytics } from '@/entities';

type Group = 'hour' | 'day' | 'week' | 'month';

interface Request {
  storeId: string | undefined;
  group: Group;
  startDate: string;
  endDate: string;
}

interface Hooks {
  analytics: Analytics | undefined;
  loading: boolean;
  error: Error | undefined;
}

export const useAnalytics = (storeId: string | undefined, startDate: Date, endDate: Date): Hooks => {
  const [analytics, setAnalytics] = useState<Analytics>();
  const { call: getAnalytics, loading, error } = useGetAnalytics();

  useEffect(() => {
    if (loading) return;

    getAnalytics({
      group: group(startDate, endDate),
      storeId: storeId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }).then((res) => {
      setAnalytics(res);
    });
  }, [storeId, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return { analytics, loading, error };
};

const useGetAnalytics = (): CallableResult<Request, Analytics> => useCallable('onCallGetAnalytics', { timeout: 300000 });

const group = (startDate: Date, endDate: Date): Group => {
  const diffDays = dayjs(endDate).diff(dayjs(startDate), 'day');

  if (diffDays === 0) {
    return 'hour';
  } else if (diffDays <= 31) {
    return 'day';
  } else if (diffDays <= 90) {
    return 'week';
  } else {
    return 'month';
  }
};
