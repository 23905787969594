import { useState } from 'react';
import firebase from 'firebase';

type Result = {
  loading: boolean
  error: Error | undefined
  upload: (file: File, path: string, metadata?: firebase.storage.UploadMetadata) => Promise<void>
};

export const useFileUpload = (): Result => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const upload = async (file: File, path: string, metadata?: firebase.storage.UploadMetadata) => {
    return new Promise<void>((resolve) => {
      const ref = firebase.storage().ref(path);
      setLoading(true);

      ref.put(file, metadata).then(() => {
        resolve();
      }).catch(error => {
        setError(error);
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  return {
    loading,
    error,
    upload,
  };
};
