import dayjs from 'dayjs';
import 'dayjs/locale/ja';

export const time = (date: Date): string => {
  return dayjs(date).locale('ja').format('MM/DD HH:mm');
};

export const hour = (date: Date): string => {
  return dayjs(date).locale('ja').format('H:mm');
}

export const date = (date: Date): string => {
  return dayjs(date).locale('ja').format('M月D日');
}

export const datetime = (date: Date): string => {
  return dayjs(date).locale('ja').format('YYYY/MM/DD HH:mm');
};

export const price = (price: number): string => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(price);
};

export const numberFormat = (value: number): string => new Intl.NumberFormat('ja-JP').format(value);
