import firebase from 'firebase';
import Modal from 'react-modal';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

Modal.setAppElement('#__next');

if (!firebase.apps.length) {
  const config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(config);
  firebase.auth().languageCode = 'ja';

  if (process.browser && location.hostname === 'localhost') {
    firebase.firestore().useEmulator('localhost', 8080)
    firebase.app().functions('asia-northeast1').useEmulator('localhost', 5001)
    firebase.storage().useEmulator('localhost', 9199)
    firebase.auth().useEmulator('http://localhost:9099')
  }
}
