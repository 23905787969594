import { useEffect, useState } from 'react';

export const useReachBottom = (): { isBottom: boolean } => {
  const [isBottom, reachBottom] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const isBottom = window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight;
      reachBottom(isBottom);
    };

    return () => {
      window.onscroll = null;
    };
  });

  return { isBottom };
};
