export const PosPlatformTypes = [
  'linkto',
  'stesso',
  'ngp'
] as const;

export type PosPlatformType = typeof PosPlatformTypes[number]

export const posPlatformTypeNames = {
  'linkto': 'Linkto',
  'stesso': 'Stesso',
  'ngp': 'OrderLinkage'
};
