import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { PosPlatform } from '@/entities';

export const usePosPlatforms = (accountId: string | undefined): [PosPlatform[] | undefined, boolean, Error | undefined] =>
  useCollectionData<PosPlatform>(
    accountId &&
    firebase
      .firestore()
      .collectionGroup('pos')
      .where('accountId', '==', accountId)
      .where('disabled', '==', false),
    { idField: 'id' },
  );
