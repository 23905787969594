import {
  integratablePlatformTypes,
  Platform,
  PlatformOperation,
  PlatformType,
  platformTypesToShow,
  sortPlatformType
} from '@/entities';
import { usePlatformOperations } from '@/hooks/collections/usePlatformOperations';
import { usePlatforms } from '@/hooks/collections/usePlatforms';
import { useAppContext } from '@/hooks/useAppContext';
import { usePlatformTypeQuery } from '@/utils';

export interface IntegratedPlatform {
  platformType: PlatformType
  platform: Platform | undefined
  operation: PlatformOperation | undefined
}

type Response = {
  integratedPlatforms: IntegratedPlatform[] | undefined
  loading: boolean
  error: Error | undefined
}

export const useIntegratedPlatforms = (storeId: string | undefined, brandId: string | undefined): Response => {
  const { account, loading } = useAppContext();
  const queriedPlatformTypes = usePlatformTypeQuery()
  const [platforms, platformsLoading, platformsError] = usePlatforms(account?.id, storeId, brandId);
  const [platformOperations, platformOperationsLoading, platformOperationsError] = usePlatformOperations(account?.id, storeId, brandId);

  const integratedPlatformTypes = Array.from(new Set([
    ...(platforms?.map(p => p.type) ?? []),
    ...(platformOperations?.map(p => p.platformType) ?? [])
  ]));

  const integratedPlatforms = platformTypesToShow(integratedPlatformTypes, [...integratablePlatformTypes, ...queriedPlatformTypes])
    .sort(sortPlatformType)
    .map(platformType => ({
      platformType,
      platform: platforms?.find(p => p.type === platformType),
      operation: platformOperations?.find(o => o.platformType === platformType)
    }));

  return {
    integratedPlatforms,
    loading: loading || platformsLoading || platformOperationsLoading,
    error: platformsError || platformOperationsError
  }
}
