import firebase from 'firebase';
import { HasId } from './core/HasId';
import { HasTimestamp } from './core/HasTimestamp';

export type ReportType = 'DAILY_SALES_BY_PLATFORM' | 'ORDER_HISTORY';
type ReportStatus = 'CREATED' | 'READY' | 'FAILED';

export interface Report extends HasId, HasTimestamp {
  accountId: string;
  storeIds: string[] | null;
  type: ReportType;
  status: ReportStatus;
  downloadUrl: string | null;
  startTime: firebase.firestore.Timestamp;
  endTime: firebase.firestore.Timestamp;
  expireTime: firebase.firestore.Timestamp;
}

export const reportTypeName = (type: ReportType): string =>
  ({
    DAILY_SALES_BY_PLATFORM: '売上集計',
    ORDER_HISTORY: '注文履歴',
  }[type]);
