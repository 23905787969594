export enum PlatformType {
  BOTEATS = 'boteats',
  DEMAECAN = 'demaecan',
  DEMAECAN_OFFICIAL = 'demaecanOfficial',
  MENU = 'menu',
  MENU_OFFICIAL = 'menuOfficial',
  SUKESAN = 'sukesan',
  UBEREATS = 'ubereats',
  UBEREATS_OFFICIAL = 'ubereatsOfficial',
  WOLT = 'wolt',
  WOLT_OFFICIAL = 'woltOfficial'
}

export const platformTypesToShow = (integratedPlatformTypes: PlatformType[], queriedPlatformTypes: PlatformType[]): PlatformType[] =>
  Object.values(PlatformType)
    .filter(platformType =>
      integratedPlatformTypes.includes(platformType)
      || canShow(platformType as PlatformType, integratedPlatformTypes)
      || queriedPlatformTypes.includes(platformType)
    )
    .sort(sortPlatformType)

export const integratablePlatformTypes = [
  PlatformType.MENU,
  PlatformType.UBEREATS,
];

// TODO: Use Integration from our API
export const sortPlatformType = (a: PlatformType, b: PlatformType): number =>
  priority[a] - priority[b]

const priority = {
  [PlatformType.UBEREATS]: 10,
  [PlatformType.UBEREATS_OFFICIAL]: 11,
  [PlatformType.DEMAECAN]: 20,
  [PlatformType.DEMAECAN_OFFICIAL]: 21,
  [PlatformType.MENU]: 30,
  [PlatformType.MENU_OFFICIAL]: 31,
  [PlatformType.WOLT]: 40,
  [PlatformType.WOLT_OFFICIAL]: 41,
  [PlatformType.SUKESAN]: 999,
  [PlatformType.BOTEATS]: 999,
} as const;

const canShow = (platformType: PlatformType, integratedPlatformTypes: PlatformType[]) => {
  switch(platformType) {
    case PlatformType.BOTEATS:
      return false;
    case PlatformType.DEMAECAN:
      return !integratedPlatformTypes.some(type => PlatformType.DEMAECAN_OFFICIAL === type)
    case PlatformType.DEMAECAN_OFFICIAL:
      return false;
    case PlatformType.MENU:
      return !integratedPlatformTypes.some(type => PlatformType.MENU_OFFICIAL === type)
    case PlatformType.MENU_OFFICIAL:
      return false;
    case PlatformType.SUKESAN:
      return false;
    case PlatformType.UBEREATS:
      return !integratedPlatformTypes.some(type => PlatformType.UBEREATS_OFFICIAL === type);
    case PlatformType.UBEREATS_OFFICIAL:
      return false;
    case PlatformType.WOLT:
      return !integratedPlatformTypes.some(type => PlatformType.WOLT_OFFICIAL === type)
    case PlatformType.WOLT_OFFICIAL:
      return false;
  }
};
