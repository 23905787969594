import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Brand } from '@/entities';

export const useBrands = (accountId: string | undefined): [Brand[] | undefined, boolean, Error | undefined] => {
  const [brands, ...rest] = useCollectionData<Brand>(
    accountId &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('brands')
      .where('disabled', '==', false),
    { idField: 'id' },
  );

  return [brands?.filter(brand => !('storeId' in brand)), ...rest];
}
