import Head from 'next/head';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AppContext } from '@/contexts/AppContext';
import { Role } from '@/entities';
import { useAccount, useAuthState, useBrands, useStores } from '@/hooks';

import '../setup';
import '@/styles/ReactDatesOverrides.scss';
import '@/styles/global.scss';

const App = ({ Component, pageProps }: AppProps) => {
  const [auth, loading] = useAuthState();
  const [role, setRole] = useState<Role | undefined>(undefined);

  const [account, loadingAccount, errorAccount] = useAccount(role?.accountId);
  const [stores, loadingStores, errorStores] = useStores(role?.accountId);
  const [brands, loadingBrands, errorBrands] = useBrands(role?.accountId);

  useEffect(() => {
    if (document && process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        autoSessionTracking: true,
        environment:
          process.env.FIREBASE_PROJECT_ID === 'foodstudio-prod' ? 'production' : 'staging',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window && window.location.hostname.endsWith('manager-staging.orderly.jp')) {
      //To use OWASP ZAP proxy
      firebase.firestore().settings({ experimentalForceLongPolling: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading) {
      if (role === undefined && auth) {
        firebase.auth().currentUser?.getIdTokenResult(true)
          .then((result) => {
            if (result.claims.account) {
              setRole({ type: 'account', accountId: result.claims.accountId });
            } else if (result.claims.store) {
              setRole({
                type: 'store',
                accountId: result.claims.accountId,
                storeId: result.claims.storeId,
              });
            }
          });
        firebase.analytics().setUserId(auth.uid);
        firebase.analytics().setUserProperties({ username: auth.displayName, email: auth.email });
        Sentry.setUser({ id: auth.uid, username: auth.displayName ?? undefined, email: auth.email ?? undefined });
      } else if (role !== undefined && auth === null) {
        setRole(undefined);
        firebase.analytics().setUserProperties({});
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    }
  }, [auth, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Head>
        <title>Orderlyマネージャー</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div style={{ height: '100%' }}>
        <AppContext.Provider value={{
          account,
          stores,
          brands,
          loading: loadingAccount || loadingStores || loadingBrands,
          error: errorAccount || errorStores || errorBrands
        }}>
          <Component {...pageProps} />
        </AppContext.Provider>
      </div>
    </>
  );
};

export default Sentry.withProfiler(App);
