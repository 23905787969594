import { PlatformType } from '@/entities';
import { useRouter } from 'next/router';

export const usePlatformTypeQuery = (): PlatformType[] => {
  const router = useRouter();
  const { boteats, demaecan, demaecanOfficial, menuOfficial, sukesan, ubereatsOfficial, woltOfficial } = router.query;

  return queryablePlatformTypes({
    includesBotEats: !!boteats,
    includesDemaecan: !!demaecan,
    includesDemaecanOfficial: !!demaecanOfficial,
    includesMenuOfficial: !!menuOfficial,
    includesSukesan: !!sukesan,
    includesUbereatsOfficial: !!ubereatsOfficial,
    includesWoltOfficial: !!woltOfficial
  });
};

const queryablePlatformTypes = (params: {
  includesBotEats?: boolean,
  includesDemaecan?: boolean,
  includesDemaecanOfficial?: boolean,
  includesMenuOfficial?: boolean,
  includesSukesan?: boolean,
  includesUbereatsOfficial?: boolean,
  includesWoltOfficial?: boolean
}): PlatformType[] => [
  params.includesBotEats ? PlatformType.BOTEATS : undefined,
  params.includesDemaecan ? PlatformType.DEMAECAN : undefined,
  params.includesDemaecanOfficial ? PlatformType.DEMAECAN_OFFICIAL : undefined,
  params.includesMenuOfficial ? PlatformType.MENU_OFFICIAL : undefined,
  params.includesSukesan ? PlatformType.SUKESAN : undefined,
  params.includesUbereatsOfficial ? PlatformType.UBEREATS_OFFICIAL : undefined,
  params.includesWoltOfficial ? PlatformType.WOLT_OFFICIAL : undefined,
]
  .filter((value): value is PlatformType => !!value);
