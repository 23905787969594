import { PlatformType } from './PlatformType';

export const DeaaSDeliveryPlatformTypes = [
  'anycarry'
] as const;

export const DefaultDeliveryPlatformTypes = [
  'boteats',
  'demaecan',
  'menu',
  'ubereats',
  'wolt'
] as const;

export type DeaaSDeliveryPlatformType = typeof DeaaSDeliveryPlatformTypes[number]
export type DefaultDeliveryPlatformType = typeof DefaultDeliveryPlatformTypes[number]
export type DeliveryPlatformType = DeaaSDeliveryPlatformType | DefaultDeliveryPlatformType;

export const mapOrderPlatformTypeToDeliveryPlatformType = (orderPlatformType: PlatformType): DefaultDeliveryPlatformType => {
  const platformType = {
    boteats: 'boteats' as const,
    demaecan: 'demaecan' as const,
    demaecanOfficial: 'demaecan' as const,
    menu: 'menu' as const,
    menuOfficial: 'menu' as const,
    sukesan: undefined,
    ubereats: 'ubereats' as const,
    ubereatsOfficial: 'ubereats' as const,
    wolt: 'wolt' as const,
    woltOfficial: 'wolt' as const,
  }[orderPlatformType];

  if (!platformType)
    throw new Error(`Default orderPlatformType for ${orderPlatformType} is not defined`);

  return platformType;
};
