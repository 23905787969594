import { useCallable } from '@/utils';
import { Report } from '@/entities';

interface CreateOperation {
  operation: 'CREATE';
  report: {
    storeIds: Report['storeIds'];
    type: Report['type'];
    startTime: number; // ミリ秒
    endTime: number; // ミリ秒
  }
}

type Request = CreateOperation

export const useReportOperation = (): { loading: boolean, error: Error | undefined, call: (params: Request) => Promise<void> } =>
  useCallable<Request, void>('onCallReportOperation');
