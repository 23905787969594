import { createContext } from 'react';
import { Account, Brand, Store } from '@/entities';

export type AppContextProps = {
  account: Account | undefined;
  stores: Store[] | undefined;
  brands: Brand[] | undefined;
  loading: boolean;
  error: Error | undefined
};

export const AppContext = createContext<AppContextProps>({
  account: undefined,
  stores: undefined,
  brands: undefined,
  loading: false,
  error: undefined
});
