import firebase from 'firebase';
import {
  AcceptanceMethod,
  BrandRelation,
  DeliveryMethod,
  HasDisabled,
  HasTimestamp,
  Item,
  PlatformType,
  PosItem,
  StoreRelation
} from './';
import { pickObject } from '@/utils';

export type PlatformReference<T extends PlatformType> = StoreRelation & BrandRelation & { type: T };
export type PlatformRelation<T extends PlatformType> = StoreRelation & BrandRelation & { platformType: T };

// TODO: Split record
export interface PlatformCommon<T extends PlatformType> extends PlatformReference<T>, HasTimestamp, HasDisabled {
  id: T
  type: T

  // PlatformProfile
  uuid?: string
  name: string

  // PlatformStatus | statuses will be mutated frequently
  isBusyUntil: firebase.firestore.Timestamp | null
  isClosed: boolean
  isOrderable: boolean
  deliveryMethod: DeliveryMethod | null

  // PlatformConfiguration | Orderly configuration
  acceptanceMethod: AcceptanceMethod
  supportsDeliveryMethod: boolean
  pickupPosItemEnabled: boolean | null | undefined // TODO: remove null / undefined after migration

  // PlatformFailureTime | should be a cache information of other immutable event logs
  orderSyncFailedTime: firebase.firestore.Timestamp | null | undefined // TODO: remove undefined after migration
  menuSyncFailedTime: firebase.firestore.Timestamp | null | undefined // TODO: remove undefined after migration
}

export type BotEatsPlatform = PlatformCommon<PlatformType.BOTEATS>;
export type DemaecanPlatform = PlatformCommon<PlatformType.DEMAECAN>;
export type DemaecanOfficialPlatform = PlatformCommon<PlatformType.DEMAECAN_OFFICIAL>;
export type MenuPlatform = PlatformCommon<PlatformType.MENU>;
export type MenuOfficialPlatform = PlatformCommon<PlatformType.MENU_OFFICIAL>;
export type SukesanPlatform = PlatformCommon<PlatformType.SUKESAN>;
export type UberEatsPlatform = PlatformCommon<PlatformType.UBEREATS>;
export type UberEatsOfficialPlatform = PlatformCommon<PlatformType.UBEREATS_OFFICIAL>;
export type WoltPlatform = PlatformCommon<PlatformType.WOLT>;
export type WoltOfficialPlatform = PlatformCommon<PlatformType.WOLT_OFFICIAL>;

export type Platform =
  | BotEatsPlatform
  | DemaecanPlatform
  | DemaecanOfficialPlatform
  | MenuPlatform
  | MenuOfficialPlatform
  | SukesanPlatform
  | UberEatsPlatform
  | UberEatsOfficialPlatform
  | WoltPlatform
  | WoltOfficialPlatform

export const unIntegratedPosItemCountOfPlatform = (
  platform: Platform,
  items: Item[],
  posItems: Pick<PosItem, 'itemIds' | 'pickupItemIds'>[]
): number =>
  items
    .filter(item => item.brandId === platform.brandId && item.platformType === platform.type)
    .filter(item =>
      isNotIntegratedWithPosItem(item, posItems)
      || (platform.pickupPosItemEnabled && isNotIntegratedAsPosPickupItem(item, posItems))
    )
    .length;

export const platformToReference = (platform: Platform): PlatformReference<PlatformType> =>
  pickObject(platform, ['id', 'accountId', 'storeId', 'brandId', 'type']);

export const platformToRelation = (platform: Platform): PlatformRelation<PlatformType> =>
  ({ ...pickObject(platform, ['accountId', 'storeId', 'brandId']), platformType: platform.type });

const isNotIntegratedWithPosItem = (item: Item, posItems: { itemIds: string[] }[]) =>
  posItems.every(posItem => !posItem.itemIds.includes(item.id));

const isNotIntegratedAsPosPickupItem = (item: Item, posItems: { pickupItemIds?: string[] }[]) =>
  posItems.every(posItem => !posItem.pickupItemIds?.includes(item.id));
