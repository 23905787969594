import { useCallback, useEffect, useState } from 'react';
import { HasId } from '@/entities';

type Response<T> = {
  selected: T | undefined;
  select: (id: string) => void;
}

export const useSelectableResource = <T extends HasId>(resources: T[] | undefined): Response<T> => {
  const [id, setId] = useState<string | undefined>(undefined);

  const select = useCallback(brandId => {
    if (!resources?.find(brand => brand.id === brandId)) return;

    setId(brandId);
  }, [resources, setId]);

  useEffect(() => {
    if (id || !resources || resources.length === 0) return;

    setId(resources[0].id);
  }, [id, resources, setId])

  return { selected: resources?.find(resource => resource.id === id), select };
};
