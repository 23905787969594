import { useState } from 'react';
import firebase from 'firebase';

export type MutationResult<T> = {
  loading: boolean
  error: Error | undefined
  update: (item: Omit<T, 'updateTime'>) => Promise<void>
};

export const useMutation = <T>(
  transform: (_: Omit<T, 'updateTime'>) => firebase.firestore.DocumentReference
): MutationResult<T> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const update = async (item: Omit<T, 'updateTime'>) => {
    setLoading(true);
    await transform(item)
      .update({
        ...item,
        updateTime: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    update,
  };
};
