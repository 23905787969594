import firebase from 'firebase';
import { MutationResult, useMutation } from '@/utils';
import { Brand } from '@/entities';

export const useBrandMutation = (): MutationResult<Brand> =>
  useMutation((brand) =>
    firebase
      .firestore()
      .collection('accounts')
      .doc(brand.accountId)
      .collection('brands')
      .doc(brand.id),
  );
