export const absolutePercentageError = (measured: number, truth: number): number =>
  Math.round(Math.abs(1 - Math.round(truth / measured * 100) / 100) * 100) / 100;

export const cosSimilarity = (a: number[], b: number[]): number =>
  Math.round(innnerProduct(a, b) / (norm(a) * norm(b)) * 100) / 100;

const innnerProduct = (vecA: number[], vecB: number[]) => {
  let product = 0;
  vecA.forEach((_, i) => {
    product += vecA[i] * vecB[i];
  })
  return product;
}

const norm = (vec: number[]) => {
  let sum = 0;
  vec.forEach((_, i) => {
    sum += vec[i] * vec[i];
  })
  return Math.sqrt(sum);
}
