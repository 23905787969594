import { Similarity } from '@/entities/SimilarityComparable';
import { Item } from '@/entities/Item';

export interface PosItem {
  id: string
  name: string
  price: number
  itemIds: string[]
  pickupItemIds?: string[]
  raw: string[]
}

export const findCandidatePosItemsForItem = (
  item: Item,
  posItems: PosItem[],
  similarityMap: Record<string, Similarity>,
): PosItem[] => {
  const similarity = (itemId: string, posItemId: string) =>
    similarityMap[itemId]?.matched[posItemId]?.similarity ?? 0

  //ToDo: Determine the threshold mathematically and statistically.
  const shouldMatch = (item: Item, posItem: PosItem) =>
    item.price === posItem.price && similarity(item.id, posItem.id) >= 0.25

  return posItems
    .filter(posItem => shouldMatch(item, posItem))
    .sort((a, b) => similarity(item.id, b.id) - similarity(item.id, a.id));
}
