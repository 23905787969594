export * from './hooks/useAuth';
export * from './hooks/useCallable';
export * from './hooks/useFileUpload';
export * from './hooks/useMutation';
export * from './hooks/usePlatformTypeQuery';
export * from './hooks/useReachBottom';
export * from './hooks/useSelectableStoreResources';
export * from './hooks/useSelectableResource';
export * from './hooks/useTokenizer';

export * from './arrayExpansion'
export * from './Formatter';
export * from './mathExpansion';
export * from './naturalLanguageProcessing';

/**
 * @param object object to filter
 * @param keys keys to filter object
 * @return filtered object by specified keys
 */
export const pickObject = <T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> =>
  Object.assign(
    {},
    ...keys
      .filter(key => object && Object.prototype.hasOwnProperty.call(object, key))
      .map(key => ({ [key]: object[key] }))
  );
