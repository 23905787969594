import firebase from 'firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Order } from '@/entities';

export const useOrder = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined,
  orderId: string | undefined
): [Order | undefined, boolean, Error | undefined] =>
  useDocumentData<Order>(
    accountId && storeId && brandId && orderId &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('stores')
      .doc(storeId)
      .collection('brands')
      .doc(brandId)
      .collection('orders')
      .doc(orderId),
    { idField: 'id' },
  );
