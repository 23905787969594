import { IpadicFeatures, Tokenizer } from 'kuromoji';
import { useEffect, useState } from 'react';
import { getTokenizer } from '../naturalLanguageProcessing';

interface Response {
  tokenizerLoading: boolean
  tokenizer: Tokenizer<IpadicFeatures> | undefined
}

export const useTokenizer = (): Response => {
  const [tokenizerLoading, setIsTokenizerLoading] = useState(false)
  const [tokenizer, setTokenizer] = useState<Tokenizer<IpadicFeatures>>();

  useEffect(() => {
    let isMounted = true //Note: To prevent memory leaks. Not required for react version 18.
    if (tokenizer) return;
    setIsTokenizerLoading(true)
    Promise.resolve(getTokenizer())
      .then(tokenizer => {
        if(isMounted) setTokenizer(tokenizer);
      })
      .then(() => setIsTokenizerLoading(false));
    return () => {
      isMounted = false
    }
  }, [tokenizer])

  return { tokenizerLoading, tokenizer }
}